import { postFile, get, post } from '@/http/request.js'
import store from '@/store/store'
import i18n from '@/lang/index'

export const uploadFile = (url, formData, config = {}) => {
  return postFile(url, formData, config)
}

// 获取集团下拉列表
export const getGroupList = (data, config = {}) => {
  return get('option/listCustomerGroup', data, config)
}

// 获取企业下拉列表
export const getCompanyList = (data, config = {}) => {
  return post('project/customers/listEnterprises', data, config)
}


// 查询企业/供应商下拉选项
export const forSaveList = (data, config = {}) => {
  return get('sys/company/option/forSave', data, config)
}

import { handleDept } from '@/utils/utils'

// 获取部门 树 数据, 级联组件；有一层 store 缓存
export const getDeptCascader = ({authFlag} = {}) => {
  return new Promise((resolve, reject) => {
    if(authFlag) {
      const cascaderHasAuth = store.state.common.cascaderHasAuth
      if (cascaderHasAuth.length) {
        return resolve(cascaderHasAuth)
      }
    } else {
      const cascader = store.state.common.cascader
      if (cascader.length) {
        return resolve(cascader)
      }
    }

    get('sys/dept/cascader/list', {authFlag}).then((res) => {
      if (res.code == 0) {
        if (res.data.length > 0) {
          const list = handleDept(res.data.length == 1 ? res.data[0].children : res.data)
          store.commit(authFlag ? 'setCascaderHasAuth' : 'setCascader', Object.freeze(list))
          resolve(Object.freeze(list))
        }
      }
      reject()
    }).catch(reject)
  })
}

// 获取部门 树 数据, 级联组件；有一层 store 缓存 项目管理编辑
export const getDeptCascaderProject = () => {
  return new Promise((resolve, reject) => {
    const cascader = store.state.common.cascaderOption
    if (cascader.children) {
      return resolve(cascader)
    }
    get('sys/dept/cascader/list', {}).then((res) => {
      if (res.code == 0) {
        if (res.data.length > 0) {
          const list = handleDept(res.data.length == 1 ? res.data[0].children : res.data)
          const data = {
            ...res.data[0],
            children: list,
          }
          store.commit('setCascaderOption', Object.freeze(data))
          resolve(Object.freeze(data))
        }
      }
      reject()
    }).catch(reject)
  })
}

// 获取字典表数据，有一层 store 缓存
export const getDictionary = () => {
  return new Promise((resolve, reject) => {
    const dictionary = store.state.common.dictionary
    if (Object.keys(dictionary).length) {
      return resolve(dictionary)
    }
    get('project/project/option').then((res) => {
      if (res.code == 0) {
        const data = res.data
        store.commit('setDictionary', Object.freeze(data))
        resolve(data)
      }
    }).catch(reject)
  })
}

export const handleTreeData = function handleTreeData(dept) {
  const list = []
  const setLevelList = (val, nodeList) => {
    const nodeListNow = [...nodeList, val.id]
    if (!val.children || val.children.length === 0) {
      list.push(nodeListNow)
    } else {
      val.children.forEach(x => {
        setLevelList(x, nodeListNow)
      })
    }
  }
  dept.forEach(x => {
    setLevelList(x, [])
  })
  return list
}

// 查询带有选中状态的部门树结构
export const queryCheckedTreeList = (req) => {
  return new Promise((resolve, reject) => {
    get('sys/dept/queryCheckedTreeList', req).then(({ code, data }) => {
      if (code === 0) {
        const dept = handleDept(data.treeModelVO.length == 1 ? data.treeModelVO[0].children : data.treeModelVO)
        const list = handleTreeData(dept)
        const operationPrivilegeIdList = data.operationPrivilegeIdList || []
        const filterList = list.filter(item => {
          const lastNum = item.at(-1)
          return operationPrivilegeIdList.includes(lastNum)
        })
        resolve({
          dept: dept,
          deptIdList: filterList,
        })
        return
      }
      reject()
    }).catch(reject)
  })
}

// 处理 【运营部门】级联数据
export const handleCascaderDept = (checkedNodes) => {
  const listLevel = {
    level1: [],
    level2: [],
  }
  const list = []
  const setLevelList = (val) => {
    const levelName = `level${val.level}`
    if (listLevel[levelName] === void 0) {
      listLevel[levelName] = []
    }
    if (!listLevel[levelName].includes(val.data.id)) {
      listLevel[levelName].push(val.data.id)
    }
    if (val.parent !== null) {
      setLevelList(val.parent)
    }
  }
  checkedNodes.forEach(x => {
    setLevelList(x)
  })
  for (const key in listLevel) {
    list.push(...listLevel[key])
  }
  return list
}


// 数据字典分类显得数据项
export const getValueList = (data) => {
  return new Promise((resolve, reject) => {
    get('sys/param/value-list.do', data)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data)
          return
        }
        reject()
      }).catch(reject)
  })
}

// 数据字典分类显得数据项
export const getBusinessTypeList = () => {
  return new Promise((resolve, reject) => {
    const businessType = store.state.common.businessType
    if (businessType.length) {
      return resolve(businessType)
    }
    getValueList({ parentId: 1000000 }).then((data) => {
      const json = data.map(item => {
        return {
          paramCode: item.paramCode,
          paramName: item.paramName,
          paramValue: item.paramValue,
          id: item.id,
        }
      })
      store.commit('setBusinessType', Object.freeze(json))
      resolve(json)
    }).catch(reject)
  })
}
// 渠道列表
export const getRecruitChannel = () => {
  return new Promise((resolve, reject) => {
    const recruitChannel = store.state.common.recruitChannel
    if (recruitChannel.length) {
      return resolve(recruitChannel)
    }
    get('sys/channel/list.do', {
      pageNo: 1,
      pageSize: 200,
    }).then((res) => {
      if (res.code == 0) {
        const data = res.data.records
        store.commit('setRecruitChannel', Object.freeze(data.records))
        resolve(data)
      }
    }).catch(reject)
  })
}


export const getIndustryList = () => {
  const isVi = i18n.locale === 'vi'
  //营销类型、获客市场活动、渠道商
  return new Promise((resolve, reject) => {
    const industry = store.state.common.industry
    if (industry.length) {
      if (isVi) {
        return resolve(industry.map(item => ({ ...item, label: item.remark1 })))
      }
      return resolve(industry)
    }
    get('option/industry', {}).then(res => {
      if (res.code == 0) {
        store.commit('setIndustry', Object.freeze(res.data.industry))
        const industrys = res.data.industry || []
        if (isVi) {
          resolve(industrys.map(item => ({ ...item, label: item.remark1 })))
        } else {
          resolve(industrys || [])
        }
        return
      }
      reject()
    }).catch(reject)
  })
}

/**
 * 获取系统用户列表，缓存
 * @param {boolean} useCache - 是否使用缓存，默认使用
 */
export const getUserOptionList = (useCache = true) => {
  return new Promise((resolve, reject) => {
    if (useCache) {
      const userList = store.state.common.userList
      if (userList.length) {
        return resolve(userList)
      }
    }
    get('sys/user/option', {}).then(res => {
      if (res.code == 0) {
        store.commit('setUserList', Object.freeze(res.data))
        resolve(res.data || [])
        return
      }
      reject()
    }).catch(reject)
  })
}

// 保存全局货币
export const saveCnyType = (val) => {
  const value = val || ''
  const cnyType = store.state.common.cnyType
  if (cnyType !== value) {
    post(`sys/user/currency/add?cnyType=${value || 0}`).then(() => {
      store.commit('setCnyType', value)
    })
  }
}

// 获取缓存的配置
export const queryCurrency = () => {
  // 币种全局默认值
  post('sys/user/currency/get').then(res => {
    store.commit('setCnyType', res.data)
  })
  // 币种列表
  get('option/enumList', { type: 'cnyTypeEnum' }).then(e => {
    if (e.code === 0) {
      let unitCnyType=[]
      let descCnyType=[]
      const data=e.data||[]
      data.forEach(x => {
        unitCnyType.push({label: x.unit, value: x.code,})
        descCnyType.push({label: x.desc, value: x.code,})
      })
      store.commit('setCnyTypeList', descCnyType)
      store.commit('setCnyTypeUnitList', unitCnyType)
    }
  })
  // 注册地
  get('option/dictList', { type: 'country' }).then(e => {
    if (e.code === 0) {
      store.commit('setRegistrationAddress', Object.freeze(e.data))
    }
  })
}

/**
 * 处理结算单数据，生成分组列表
 * @param {Array} data
 * @returns  {Array}
 */
export const handleSettleList = (data) => {
  let list = []
  data.forEach((item) => {
    let obj = list.find(listItem => listItem.label === item.customerName)
    let serviceFlag = item.serviceFeeFlag === 1
    if (obj) {
      obj.options.push({
        value: item.settleId,
        label: item.settleNo,
        customerName: item.customerName || '',
        settleAmount: item.settleAmount,
        projectName: item.projectName,
        serviceFlag: serviceFlag,
      })
    } else {
      list.push({
        label: item.customerName || '',
        value: item.settleId,
        projectName: item.projectName,
        options: [{
          value: item.settleId,
          label: item.settleNo,
          projectName: item.projectName,
          customerName: item.customerName || '',
          settleAmount: item.settleAmount,
          serviceFlag: serviceFlag,
        }],
      })
    }
  })
  return Object.freeze(list)
}

/**
 * 获取关联结算单
 * @param {Object} req 请求参数
 * @param {Object} rest 此参数用在结算单页面勾选，跳转到开票页面，
 * 带过来的结算单数据，若下拉没有此结算单，则把带来的数据填充到下拉数据中，以防只显示id
 * @returns {Promise}
 */
export const getSettleBIllList = (req, rest) => {
  return new Promise((resolve) => {
    post('erp-finance/invoiceApply/listSettleBills', req).then((res) => {
      const data = res.data || []
      if (rest && rest.settleId && !data.find(item => item.settleId === rest.settleId)) {
        data.push(rest)
      }
      resolve({
        list: Object.freeze(data),
        groupList: handleSettleList(data),
      })
    })
  })
}

